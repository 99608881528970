import styled from "@emotion/styled";
import React from "react";

import Button from "../Button";
import ButtonAnchor from "../Button/ButtonAnchor";
import CabinGallery from "./CabinGallery";
import ProsItem from "./ProsItem";

import { WpMediaItem } from "../../../gatsby-types";

import { media, Breakpoint, themeColor } from "../../theme";
import { slugify } from "../../utils";

const GalleryWrapper = styled.div``;
const DetailsWrapper = styled.div``;

const CabinName = styled.p`
  font-family: ${({ theme }) => theme.font.josefinSans};
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0 0 20px;

  ${media(Breakpoint.Mobile)} {
    font-size: 36px;
    line-height: 48px;
  }

  ${media(Breakpoint.Tablet)} {
    margin: 0 0 10px;
  }
`;

const Description = styled.div`
  p {
    font-size: 14px;
    line-height: 26px;
    color: ${themeColor("black")};

    margin: 0 0 20px;

    &:last-of-type {
      margin: 0;
    }
  }
`;

const Pros = styled.div`
  margin: 0 0 20px;

  ${media(Breakpoint.Tablet)} {
    margin: 0 0 30px;
  }

  ${ProsItem} {
    margin: 0 0 10px;

    &:last-of-type {
      margin: 0;
    }

    ${media(Breakpoint.Tablet)} {
      display: inline-flex;
      margin: 0 30px 10px 0;

      &:last-of-type {
        margin: 0;
      }
    }
  }
`;

const Price = styled.p``;

const StartingAtWrapper = styled.div`
  p {
    font-family: ${({ theme }) => theme.font.josefinSans};
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin: 0 0 10px;

    &${Price} {
      font-size: 36px;
      line-height: 48px;
      letter-spacing: 0.1em;
      margin: 0;

      span {
        font-size: 14px;
        padding-left: 5px;
        position: relative;
        top: -1px;
      }
    }
  }
`;

const CabinComingSoon = styled.p`
  font-family: ${({ theme }) => theme.font.josefinSans};
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.2em;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  margin: 0;

  ${media(Breakpoint.TabletPlus)} {
    margin-top: 60px;
  }
`;

const Actions = styled.div`
  margin: 20px 0 0;
  display: grid;
  grid-gap: 10px;

  ${media(Breakpoint.TabletPlus)} {
    margin: 40px 0 0;
  }

  ${media(Breakpoint.Desktop)} {
    grid-template-columns: 1fr 325px;
    grid-gap: 20px;
  }

  ${ButtonAnchor} {
    display: block;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;

    ${media(Breakpoint.Desktop)} {
      max-width: none;
      width: 100%;
      margin: 0;
    }

    ${Button} {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const LocationAnchor = styled.div`
  height: 1px;
  width: 100%;
  background: transparent;
  position: absolute;
  top: -80px;
`;

export interface GalleryImages {
  image: WpMediaItem;
}

export interface CabinDetailsProps {
  className?: string;
  bookingUrl: string;
  comingSoon?: boolean | null;
  description: string;
  name: string;
  bath: number;
  bedroom: number;
  guests: number;
  petFriendly: boolean;
  startingAt: number;
  galleryImage: WpMediaItem;
  galleryImageLightbox: WpMediaItem;
  gallery: GalleryImages[];
  mainImage: WpMediaItem;
  mainImageMobile: WpMediaItem;
}

const CabinDetails: React.FC<CabinDetailsProps> = ({
  className,
  bath,
  bedroom,
  bookingUrl,
  comingSoon,
  gallery,
  galleryImage,
  galleryImageLightbox,
  guests,
  petFriendly,
  description,
  name,
  startingAt,
}) => {
  let cabinDetailsElement: JSX.Element;
  if (comingSoon) {
    cabinDetailsElement = <CabinComingSoon>Coming Soon</CabinComingSoon>;
  } else {
    cabinDetailsElement = (
      <React.Fragment>
        <Pros>
          {!!petFriendly && <ProsItem icon="pets" label="Pets Friendly" />}
          <ProsItem icon="guests" label={`${guests} Guests`} />
          <ProsItem icon="bedroom" label={`${bedroom || 0} Bedroom${bedroom > 1 ? "s" : ""}`} />
          <ProsItem icon="bath" label={`${bath || 0} Bath`} />
        </Pros>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        <Actions>
          <StartingAtWrapper>
            <p>Starting At</p>
            <Price>
              ${startingAt}
              <span>per night</span>
            </Price>
          </StartingAtWrapper>
          <ButtonAnchor href={bookingUrl} target="_blank" rel="noopener noreferrer">
            <Button variant="transparentDark">Check Availability</Button>
          </ButtonAnchor>
        </Actions>
      </React.Fragment>
    );
  }
  return (
    <div className={className}>
      <LocationAnchor id={slugify(name)} />
      <GalleryWrapper>
        <CabinGallery
          name={name}
          gallery={gallery}
          galleryImage={galleryImage}
          galleryImageLightbox={galleryImageLightbox}
        />
      </GalleryWrapper>
      <DetailsWrapper>
        <CabinName>{name}</CabinName>
        {cabinDetailsElement}
      </DetailsWrapper>
    </div>
  );
};

export default styled(CabinDetails)`
  position: relative;
  display: grid;
  grid-gap: 40px;

  ${media(Breakpoint.Tablet)} {
    grid-template-columns: 335px 1fr;
    justify-content: space-between;
  }

  ${media(Breakpoint.TabletPlus)} {
    grid-template-columns: 440px 1fr;
  }

  ${CabinGallery} {
    margin: 0 auto;

    ${media(Breakpoint.Tablet)} {
      margin: 0;
    }
  }
`;
