import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { CabinDetailsProps } from ".";

import { media, Breakpoint, alpha } from "../../theme";

const MoreOverlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => alpha(theme.color.grey700, 0.7)};
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;

const MainImage = styled.div`
  cursor: pointer;
`;

const ThumbnailItem = styled.div``;

const Thumbnails = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 68px);
  justify-content: space-between;
  margin: 20px 0 0;

  ${ThumbnailItem} {
    position: relative;
    width: 100%;
    height: 68px;
    cursor: pointer;

    > img {
      width: 100%;
      height: auto;
    }
  }

  ${media(Breakpoint.LegacyMobile)} {
    grid-template-columns: repeat(4, 60px);

    ${ThumbnailItem} {
      height: 60px;
    }
  }

  ${media(Breakpoint.TabletPlus)} {
    grid-template-columns: repeat(4, 95px);

    ${ThumbnailItem} {
      height: 95px;
    }
  }
`;

interface CabinGalleryProps {
  className?: string;
  name: string;
  gallery?: CabinDetailsProps["gallery"];
  galleryImage: CabinDetailsProps["galleryImage"];
  galleryImageLightbox: CabinDetailsProps["galleryImageLightbox"];
}

const CabinGallery: React.FC<CabinGalleryProps> = ({
  className,
  name,
  gallery,
  galleryImage,
  galleryImageLightbox,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const openLightbox = (photoIndex: number) => {
    setPhotoIndex(photoIndex);
    setIsOpen(true);
  };

  const images: string[] = [galleryImageLightbox.localFile?.childImageSharp?.fluid?.src || ""];
  if (gallery && gallery.length > 0) {
    gallery.forEach((item) => {
      const fullImageUrl = item.image.localFile?.childImageSharp?.fluid?.src || "";
      if (fullImageUrl) {
        images.push(fullImageUrl);
      }
    });
  }
  return (
    <div className={className}>
      {galleryImage.localFile && (
        <MainImage onClick={() => openLightbox(0)}>
          <GatsbyImage
            image={galleryImage.localFile.childImageSharp?.gatsbyImageData}
            alt={name}
            objectPosition="top center"
          />
        </MainImage>
      )}
      {gallery && gallery.length > 0 && (
        <Thumbnails>
          {[...gallery].splice(0, 4).map((item, idx) => {
            if (item.image.localFile?.childImageSharp?.resize?.src) {
              return (
                <ThumbnailItem key={idx} onClick={() => openLightbox(idx + 1)}>
                  <img
                    alt={`${name} - #${idx}`}
                    src={item.image.localFile?.childImageSharp?.resize.src}
                    width={190}
                    height={190}
                  />
                  {idx === 3 && (
                    <MoreOverlay>
                      <img alt="logo" src={`/gallery-more.svg`} />
                    </MoreOverlay>
                  )}
                </ThumbnailItem>
              );
            } else {
              return null;
            }
          })}
        </Thumbnails>
      )}

      {isOpen && (
        <Lightbox
          imageTitle={`${name} - #${photoIndex + 1}`}
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
  );
};

export default styled(CabinGallery)`
  width: 100%;
  max-width: 335px;

  ${media(Breakpoint.TabletPlus)} {
    max-width: 440px;
  }

  ${MainImage} {
    position: relative;
    width: 100%;
    height: 300px;

    > div {
      height: 100%;
    }
  }
`;
