import styled from "@emotion/styled";
import React from "react";

const Icon = styled.img`
  display: block;
`;

const Label = styled.p`
  font-family: ${({ theme }) => theme.font.josefinSans};
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  height: 11px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin: 0;
`;

export interface ProsItemProps {
  className?: string;
  icon: "bath" | "bedroom" | "guests" | "pets";
  label: string;
}

const ProsItem: React.FC<ProsItemProps> = ({ className, icon, label }) => {
  return (
    <div className={className}>
      <Icon alt={label} css={{ marginRight: "10px" }} src={`/pros-${icon}.svg`} width={16} height={16} />
      <Label>{label}</Label>
    </div>
  );
};

export default styled(ProsItem)`
  display: flex;
  align-items: center;
`;
