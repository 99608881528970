import styled from "@emotion/styled";

import { media, Breakpoint, themeColor } from "../../theme";

export default styled.div`
  background: ${themeColor("grey100")};
  padding: 0 20px;

  ${media(Breakpoint.Tablet)} {
    padding: 0 40px;
  }
`;
