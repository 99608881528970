/** @jsx jsx */
import { jsx } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import React from "react";
import Seo from "gatsby-plugin-wpgraphql-seo";
import { Query } from "../../gatsby-types";

import CabinDetails from "../components/CabinDetails";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import SectionGrey from "../components/SectionGrey";
import { CabinDetailsProps } from "../components/CabinDetails";

import { media, Breakpoint } from "../theme";

type PropsType = {
  data: Query;
};

export type acfCabinsProps = {
  hero: string;
  heroSubheader: string;
  cabins: CabinDetailsProps[];
};

const CabinDetailsList = styled.div`
  display: grid;
  grid-gap: 40px;
  max-width: 1130px;
  margin: 0 auto;

  ${media(Breakpoint.Tablet)} {
    grid-gap: 90px;
  }
`;

const Cabins: React.VFC<PropsType> = (props) => {
  if (!props.data.wpPage || !props.data.wpPage.acfCabins) {
    return null;
  }

  const { hero, heroSubheader, cabins } = props.data.wpPage.acfCabins as acfCabinsProps;

  return (
    <Layout>
      <Seo post={props.data.wpPage} />
      <Hero page="cabins" header={hero} paragraph={heroSubheader} />
      <SectionGrey
        css={{
          paddingTop: "40px",
          paddingBottom: "40px",
          [media(Breakpoint.Tablet)]: { paddingTop: "100px", paddingBottom: "100px" },
        }}
      >
        <CabinDetailsList>
          {cabins.map((cabin, idx) => (
            <CabinDetails key={idx} {...cabin} />
          ))}
        </CabinDetailsList>
      </SectionGrey>
    </Layout>
  );
};

export const pageQuery = graphql`
  query CabinsPage($wpId: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    wpPage(id: { eq: $wpId }) {
      id
      title
      nodeType
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfCabins {
        hero
        heroSubheader
        cabins {
          bath
          bedroom
          bookingUrl
          comingSoon
          description
          guests
          name
          petFriendly
          startingAt
          mainImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1110, height: 720)
              }
            }
          }
          galleryImage: mainImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 880, height: 600)
              }
            }
          }
          galleryImageLightbox: mainImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 95) {
                  src
                }
              }
            }
          }
          gallery {
            image {
              localFile {
                childImageSharp {
                  resize(width: 190, height: 190) {
                    src
                  }
                  fluid(maxWidth: 2000, quality: 95) {
                    src
                  }
                }
              }
            }
          }
          mainImageMobile: mainImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 670, height: 304)
              }
            }
          }
        }
      }
    }
  }
`;

export default Cabins;
